import React from "react";
import clsx from "classnames";
import SourceFlowText from "@sourceflow-uk/sourceflowtext";
import { getGlobal } from "@/functions/getGlobal";

import styles  from './styles.module.scss';

export default function Text({ className, path }) {

    const global = getGlobal();

    return (
        <div className={clsx(styles.cont, className)}>
            <SourceFlowText global={global} path={path} type="html" />
        </div>
    )
}